@use '../../../base';

button.menu-toggle
{
    display: block;
    margin-left: auto;
    margin-right: .5em;
    margin-top: .5em;
    border: 0;
    padding: 2px;
    line-height: 1em;
    font-size: 1em;
    color: base.$siteLinkColor;
    background-color:inherit;

    &:focus, &:hover {
      outline: 0;
      border: 0;
    }

    &:hover {
      color: base.$siteLinkHover;
    }
    
    @include base.deviceSize('tablet') {
      display: none;
    }
    
}
  

