@use "sass:map";
/*************** Custom Pallet ***************/
/* SCSS RGB *//*
$space-cadet: rgba(30, 45, 72, 1);
$lilac-luster: rgba(192, 155, 175, 1);
$pine-tree: rgba(36, 46, 15, 1);
$desert-sand: rgba(225, 203, 183, 1);
$queen-pink: rgba(232, 216, 224, 1);

/* SCSS Gradient *//*
$gradient-top: linear-gradient(0deg, #1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
$gradient-right: linear-gradient(90deg, #1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
$gradient-bottom: linear-gradient(180deg, #1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
$gradient-left: linear-gradient(270deg, #1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
$gradient-top-right: linear-gradient(45deg, #1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
$gradient-bottom-right: linear-gradient(135deg, #1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
$gradient-top-left: linear-gradient(225deg, #1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
$gradient-bottom-left: linear-gradient(315deg, #1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
$gradient-radial: radial-gradient(#1e2d48ff, #c09bafff, #242e0fff, #e1cbb7ff, #e8d8e0ff);
/*************** End Custom Pallet ***************/

/*************** Piper Lips Photo Pallet ***************/
/* SCSS RGB *//*
$cafe-noir: rgba(77, 59, 37, 1);
$shadow: rgba(145, 119, 84, 1);
$banana-mania: rgba(253, 236, 190, 1);
$bittersweet-shimmer: rgba(181, 86, 84, 1);
$pink-lace: rgba(254, 219, 247, 1);

/* SCSS Gradient *//*
$gradient-top: linear-gradient(0deg, #4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
$gradient-right: linear-gradient(90deg, #4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
$gradient-bottom: linear-gradient(180deg, #4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
$gradient-left: linear-gradient(270deg, #4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
$gradient-top-right: linear-gradient(45deg, #4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
$gradient-bottom-right: linear-gradient(135deg, #4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
$gradient-top-left: linear-gradient(225deg, #4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
$gradient-bottom-left: linear-gradient(315deg, #4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
$gradient-radial: radial-gradient(#4D3B25ff, #917754ff, #FDECBEff, #B55654ff, #FEDBF7ff);
/*************** End Piper Lips Photo Pallet ***************/

/***** Rose Photo Pallet  **************/
/* SCSS RGB *//*
$champagne-pink: rgba(231, 218, 210, 1);
$pale-purple-pantone: rgba(251, 229, 244, 1);
$satin-sheen-gold: rgba(187, 147, 37, 1);
$ebony: rgba(79, 87, 71, 1);
$laurel-green: rgba(167, 182, 163, 1);

/* SCSS Gradient *//*
$gradient-top: linear-gradient(0deg, #E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
$gradient-right: linear-gradient(90deg, #E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
$gradient-bottom: linear-gradient(180deg, #E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
$gradient-left: linear-gradient(270deg, #E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
$gradient-top-right: linear-gradient(45deg, #E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
$gradient-bottom-right: linear-gradient(135deg, #E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
$gradient-top-left: linear-gradient(225deg, #E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
$gradient-bottom-left: linear-gradient(315deg, #E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
$gradient-radial: radial-gradient(#E7DAD2ff, #FBE5F4ff, #BB9325ff, #4F5747ff, #A7B6A3ff);
/***** End Rose Photo Pallet  **************/



$pale-purple-pantone: rgba(251, 229, 244, 1);
$satin-sheen-gold:    rgba(187, 147, 37, 1);
$ebony:               rgba(79, 87, 71, 1);
$laurel-green:        rgba(167, 182, 163, 1);
$banana-mania:        rgba(253, 236, 190, 1);
$space-cadet:         rgba(30, 45, 72, 1);
$pine-tree:           rgba(36, 46, 15, 1);
$desert-sand:         rgba(225, 203, 183, 1);
$queen-pink: rgba(232, 216, 224, 1);
$lilac-luster: rgba(192, 155, 175, 1);
$pink-lace: rgba(254, 219, 247, 1);


$champagne-pink: rgba(231, 218, 210, 1);
$cafe-noir: rgba(77, 59, 37, 1);
$shadow: rgba(145, 119, 84, 1);
$bittersweet-shimmer: rgba(181, 86, 84, 1);


/***************************************************
 ******************* Color Theme *******************
 ***************************************************/
$siteBackColor:       $champagne-pink;
$siteTextColor:       $cafe-noir;
$siteAccentColor:     $shadow;
$siteLinkColor:       $bittersweet-shimmer;

$siteHeadingColor:    lighten($siteTextColor, 25%);
$siteLinkHover:       lighten($siteLinkColor, 50%);
$siteBorderColor:     darken($siteAccentColor, 25%);
$boxShadow:           darken($siteBorderColor, 25%);

/***************************************************
 ******************* Device Breakpoints ************
 ***************************************************/

$deviceBreakpoints: (
  "phone"   : 340px,
  "tablet"  : 768px,
  "laptop"  : 900px,
  "desktop" : 1200px,
  "large"   : 1440px
);


@mixin deviceSize($size) {
  @if map-has-key($deviceBreakpoints, $size) {
    $sValue: map.get($deviceBreakpoints, $size);
    @media only screen and (min-width: $sValue + 1) {  
      @content;
    }
  }
}


@mixin dropShadow($color, $blur) {
  text-shadow: 1px 1px $blur darken($color, 10%);
    
}

@mixin prefix($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    -#{$prefix}-#{$property}: $value;
  }
  #{$property}: $value;
}

@mixin pageTitle() {
  color: $siteHeadingColor;
  font-size: larger;
  font-weight: bold;
  letter-spacing: .05em;
  @include dropShadow($siteTextColor, 0);
}


@mixin forSize($breakpoint) {

}


body {
  color: $siteTextColor;
  background-color: lighten($siteBackColor, 10%);
}

img {
  max-width: 100%;
  display: block;
}



