@use '../../../../base';

a.linkitem {
    display: block;
    padding: .75em 15px;
    line-height: .75em;
    font-size: 1em;
    color: base.$siteLinkColor;
    text-decoration: none;

    @include base.deviceSize('tablet') {
      display: inline;
      border: 0 !important; /* Remove borders from off-canvas styling */
      color: base.$siteLinkColor;
      padding: .5em 1em;
      line-height: .5em;
      font-size: 1em;
    }
    
    &:hover,
    &:focus {
      color: base.$siteLinkHover;
      outline: 0;
      border: 0;

      @include base.deviceSize('tablet') {
        background: none; 
        color: lighten(base.$siteLinkHover,25%);
      }
    }
}
