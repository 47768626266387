@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap);
/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

/* @import url('https://fonts.googleapis.com/css2?family=Antic+Slab&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Antic+Slab&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap') */
/* @import url('https://fonts.googleapis.com/css2?family=Antic+Slab&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap'); */

html, body {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:'Source Code Pro', monospace;
}



main {
  min-height: 87vh;
}


@media screen, (min-width: 1024px) {

  main {
    width: 75%;
    justify-content: center;
  }

}


body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}header{color:#4d3b25}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}header{position:-webkit-sticky;position:sticky;top:0;background-color:#e7dad2;display:grid;grid-template-columns:3fr 1fr;border-bottom:3px ridge #a1845d;min-height:8vh}@media only screen and (min-width: 769px){header{padding:0;margin:0}}#content-container{grid-column:1}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}#content-container{padding:.5em;line-height:.75}#content-container h1{font-family:Philosopher;font-size:1.75em;letter-spacing:.05em;margin:0;font-weight:800;text-shadow:1px 1px 2px #2b2114}#content-container h1 a{text-decoration:none;color:#a37d4e}#content-container h1 a:hover{color:#fbf8f6}#content-container h2{font-family:Roboto;font-size:small;color:#81633e;margin:1em 0 0 0;padding-left:1.25em;letter-spacing:.05em;font-weight:normal}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}#nav-container{position:relative;grid-column:2;padding:0;height:100%;width:100%;justify-content:right}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}.main-menu{position:absolute;transition:right .3s ease,box-shadow .3s ease;z-index:999;height:100%;width:100%;justify-content:right}.main-menu:target{display:block;outline:none;box-shadow:0 0 3px #000}@media only screen and (min-width: 769px){.main-menu{display:block;position:relative;left:auto;box-shadow:none !important}}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}div#links{list-style:none;margin-left:auto;margin-right:2px;padding:0;background-color:#d5bfb1;border:1px solid #403525;width:-webkit-max-content;width:-moz-max-content;width:max-content;position:relative;z-index:1000}@media only screen and (min-width: 769px){div#links{display:grid;grid-template-columns:fit-content(33%) fit-content(33%) fit-content(33%);width:-webkit-min-content;width:-moz-min-content;width:min-content;margin:0 0 0 auto;background:none;border:none}}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}a.linkitem{display:block;padding:.75em 15px;line-height:.75em;font-size:1em;color:#b55654;text-decoration:none}@media only screen and (min-width: 769px){a.linkitem{display:inline;border:0 !important;color:#b55654;padding:.5em 1em;line-height:.5em;font-size:1em}}a.linkitem:hover,a.linkitem:focus{color:#fff;outline:0;border:0}@media only screen and (min-width: 769px){a.linkitem:hover,a.linkitem:focus{background:none;color:#fff}}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}button.menu-toggle{display:block;margin-left:auto;margin-right:.5em;margin-top:.5em;border:0;padding:2px;line-height:1em;font-size:1em;color:#b55654;background-color:inherit}button.menu-toggle:focus,button.menu-toggle:hover{outline:0;border:0}button.menu-toggle:hover{color:#fff}@media only screen and (min-width: 769px){button.menu-toggle{display:none}}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}main#home-page{display:grid;grid-template-rows:.5fr 1fr;background-color:#f9f5f3;color:#4d3b25;padding:0 1em}section.content{padding:.5em;height:-webkit-min-content;height:-moz-min-content;height:min-content}section.photo{width:98%;height:-webkit-min-content;height:-moz-min-content;height:min-content;padding:1em 0 0 0}div.frame{margin:auto;border:solid 2px #403525;padding:3px;width:200px;height:250px;background-color:#917754;box-shadow:1px 2px 3px #000}.photo .frame img{margin:0;border:thin #000;width:100%;height:auto;box-shadow:0 0 3px #000}article h3{color:#a37d4e;font-size:larger;font-weight:bold;letter-spacing:.05em;text-shadow:1px 1px 0 #2b2114;margin:.25em 0 1em 0}article p{padding:0 .5em}@media(min-width: 400px){div.frame{width:300px;height:375px}}@media(min-width: 640px){main#home-page{margin:auto;display:grid;grid-template-columns:2fr 1fr;grid-template-rows:0}div.frame{width:300px;height:375px}}@media(min-width: 768px){main#home-page{margin:auto;display:grid;grid-template-columns:2fr 1fr;grid-template-rows:0}div.frame{width:330px;height:413px}}@media screen and (min-width: 1024px){main#home-page{margin:auto;display:grid;grid-template-columns:2fr 1fr;grid-template-rows:0}div.frame{width:380px;height:474px}}@media screen and (min-width: 1440px){main#home-page{margin:auto;display:grid;grid-template-columns:2fr 1fr;grid-template-rows:0}div.frame{width:456px;height:569px}}@media(max-height: 400px){div.frame{width:200px;height:250px}}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}main#about-page{background-color:#f9f5f3;color:#4d3b25;padding:1em}@media only screen and (min-width: 901px){main#about-page{margin:auto}}section.content{padding:.5em}article h3{color:#a37d4e;font-size:larger;font-weight:bold;letter-spacing:.05em;text-shadow:1px 1px 0 #2b2114;margin:.5em 0}article p{padding:0 .5em}article p span.h3{color:#a37d4e;font-size:larger;font-weight:bold;letter-spacing:.05em;text-shadow:1px 1px 0 #2b2114}article p span.aside{font-weight:300;font-style:italic;font-size:1em}@media(min-width: 768px){main#about-page{margin:auto}}@media screen and (min-width: 1024px){main#about-page{margin:auto}}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}main#blog-page{background-color:#f9f5f3;color:#4d3b25;padding:1em;line-height:1.25}main#blog-page h1{color:#a37d4e;font-size:larger;font-weight:bold;letter-spacing:.05em;text-shadow:1px 1px 0 #2b2114;margin:.5em 0}main#blog-page h2{font-size:1.25em;margin:1.5em 0 .75em 0}main#blog-page p{margin:1.25em 0}main#blog-page pre{margin:0;padding:1em .5em;background-color:#fff;border:1px groove #403525}main#blog-page code{display:block;height:-webkit-min-content;height:-moz-min-content;height:min-content;overflow:auto;font-size:smaller}@media(max-width: 600px){#blog-page img{height:auto;width:350px}}@media(max-width: 800px){#blog-page img{height:auto;width:750}}@media screen and (min-width: 1024px){main#blog-page{margin:auto}#blog-page pre{width:60%}}
body{color:#4d3b25;background-color:#f9f5f3}img{max-width:100%;display:block}footer{position:-webkit-sticky;position:sticky;bottom:0;background-color:#e7dad2;padding:.5em 1em;font-size:smaller;border-top:3px ridge #a1845d;min-height:5vh}
