@use '../base';

footer {
    position: sticky;
    bottom: 0;
    background-color: base.$siteBackColor;
    padding: .5em 1em;
    font-size: smaller;
    border-top: 3px ridge lighten(base.$siteBorderColor, 30%);
    min-height: 5vh;
}